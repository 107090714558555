// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-crossfit-js": () => import("./../../../src/pages/crossfit.js" /* webpackChunkName: "component---src-pages-crossfit-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-personal-training-js": () => import("./../../../src/pages/personal-training.js" /* webpackChunkName: "component---src-pages-personal-training-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-weightloss-and-nutrition-js": () => import("./../../../src/pages/weightloss-and-nutrition.js" /* webpackChunkName: "component---src-pages-weightloss-and-nutrition-js" */)
}

